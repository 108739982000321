<template>
  <div ref="modalPage" class="modal_page center">
    <form ref="modalWindow" class="modal_window d-flex d-col space-between" @submit.prevent="post">
      <div class="d-flex space-between align-center">
        <div class="font-18">
          {{ $t('modal.appInfo.heading') }}
        </div>
        <s-icon color="grey" @click="closeModal">
          close
        </s-icon>
      </div>
      <div class="body pt-20">
        <div class="center pa-20">
          <Logo  class="py-50"/>
        </div>

        <div class="item">
          {{ $t('modal.appInfo.version') }}: {{ version }}
        </div>

        <div class="item">
          {{ $t('modal.appInfo.build') }}: {{ hash }}
        </div>

        <div class="item">
          {{ $t('modal.appInfo.sales') }}:
          <a target="_blank" href="mailto:sales@securmeet.com">sales@securmeet.com</a>
        </div>

        <div class="item">
          {{ $t('modal.appInfo.support') }}:
          <a target="_blank" href="mailto:support@securmeet.com">support@securmeet.com</a>
        </div>

        <div class="item">
          {{ $t('modal.appInfo.abuse') }}:
          <a target="_blank" href="mailto:abuse@securmeet.com">abuse@securmeet.com</a>
        </div>

        <div class="item">
          2024 <s-icon color="white" height="16">
            copyright
          </s-icon> securCom
          Inc.
        </div>
      </div>

      <div class="d-flex justify-end mt-40">
        <s-btn type="button"  class="green" @click="closeModal">
          {{ $t('modal.close') }}
        </s-btn>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { gsap } from "gsap";
import { useStore } from 'vuex';
import Logo from '@/components/Atoms/Logo';

const modalPage = ref(null);
const modalWindow = ref(null);
const store = useStore();
const hash = process.env.VUE_APP_GIT_HASH;
const version = process.env.VUE_APP_VERSION;

onMounted(() => {
  gsap.from(modalPage.value, {
    opacity: 0,
    duration: 0.3,
    ease: "power2.out",
  });

  gsap.from(modalWindow.value, {
    scale: 0.7,
    duration: 0.3,
    ease: "power2.out",
  });
});

const post = () => {
  closeModal();
};

const closeModal = () => {
  gsap.to(modalPage.value, {
    opacity: 0,
    duration: 0.3,
    ease: "power2.out",
  });
  gsap.to(modalWindow.value, {
    scale: 0.7,
    duration: 0.3,
    ease: "power2.out",
    onComplete: () => store.commit("modals/appInfo", false),
  });
};

</script>

<style scoped>
.modal_page {
  position: fixed;
  left: 0;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(41, 41, 41, 0.6);
  z-index: 8;
}

.modal_window {
  width: 450px;
  padding: 30px;
  box-shadow: 0 4px 10px 0 rgba(61, 16, 16, 0.35);
  background-color: var(--shade1);
  border-radius: 15px;
}

a {
  color: var(--black);
  text-decoration: underline;
}

.item {
  padding: 10px 0;
}

.logo {
  width: 100%;
  max-width: 300px;
}

@media only screen and (max-width: 576px) {
  .modal_window {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
}
</style>